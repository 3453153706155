import { User } from 'components/common/User';
import { Button } from 'components/shared/Button';
import { AdminGradientIconActive } from 'components/shared/Icons/AdminGradientIconActive';
import { BasketGradientIcon } from 'components/shared/Icons/BasketGradientIcon';
import { GlobusGradientIcon } from 'components/shared/Icons/GlobusGradientIcon';
import { HeartGradienIcon } from 'components/shared/Icons/HeartGradienIcon';
import { StarAdminIcon } from 'components/shared/Icons/StarAdminIcon';
import { LogoutGradientIcon } from 'components/shared/Icons/LogoutGradientIcon';
import { ShapeGradientIcon } from 'components/shared/Icons/ShapeGradientIcon';
import { SearchComponent } from 'components/shared/SearchComponent';
import { routers } from 'constants/routers';
import { NavLink } from 'react-router-dom';
import { RUSSIAN_LOCALE } from 'utils';
import classes from '../Header.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { useCartStore } from 'contexts/CartContext';
import { useMarketPlaceStore } from 'contexts/MarketPlaceContext';
import { HeaderNavProps } from './HeaderNav.props';
import { Menu } from 'components/shared/Menu';
import { LinksPolitic } from 'components/shared/LinksPolitic';
import HeaderAdminList from './HeaderAdminList/HeaderAdminList';
import { authStore } from '../../../../stores/authStore';

const HeaderNav = observer(
  ({
    openSearch,
    isDisableHeader,
    setOpenSearch,
    handleOpenLocalPreferencesModal,
    modalListItems,
    isConfirmedEmail,
    allowLogout,
    isManager,
    isAdmin,
    disabled,
    isSuccessLocalPreferences,
    isActiveLink,
    path,
    userName,
    goToKokocID,
    goToGrowthProgram,
    resetCookies,
    isTestUser,
    active,
    closeMenu,
    isShowHeaderAdminList,
    setIsOpenLocalPreferencesModal,
    userClient,
  }: HeaderNavProps) => {
    const { t } = useTranslation();
    const { width } = useWindowWidth();
    const cartStore = useCartStore();
    const marketPlaceStore = useMarketPlaceStore();
    const favoriteCount = toJS(marketPlaceStore).favoriteCount;
    const countCart = cartStore.cart ? cartStore.cart.services_count : 0;

    return (
      <nav className={classes['header__nav']}>
        <Menu
          active={active}
          disabled={isDisableHeader}
          closeMenu={closeMenu}
        />

        <div className={classes['header__block']}>
          <div className={classes['header__search']}>
            {(openSearch || width > 1024) && (
              <SearchComponent
                disabled={isDisableHeader}
                setOpenSearch={setOpenSearch}
              />
            )}
          </div>

          <ul className={classes['header__list']}>
            <li className={clsx(classes['header__list-item'])}>
              <NavLink
                to={routers.favorites}
                className={(navData) =>
                  clsx(
                    classes['header__list-link'],
                    navData.isActive && classes.active,
                    isDisableHeader && classes.disabled
                  )
                }
              >
                <div className={classes['header__counter']}>
                  <HeartGradienIcon />
                  {favoriteCount !== undefined && favoriteCount !== 0 && (
                    <div className={classes['header__counter-count']}>
                      <span>{favoriteCount}</span>
                    </div>
                  )}
                </div>
              </NavLink>
            </li>

            <li className={clsx(classes['header__list-item'])}>
              <NavLink
                to={routers.cart}
                className={(navData) =>
                  clsx(
                    classes['header__list-link'],
                    classes['cart'],
                    navData.isActive && classes.active,
                    isDisableHeader && classes.disabled
                  )
                }
                data-step-solutions_details-4
              >
                <div className={classes['header__counter']}>
                  <BasketGradientIcon />
                  {countCart !== 0 && countCart !== undefined && (
                    <div className={classes['header__counter-count']}>
                      <span>{countCart}</span>
                    </div>
                  )}
                </div>
              </NavLink>
            </li>

            {RUSSIAN_LOCALE && userClient && (
              <li
                className={clsx(
                  classes['header__list-item'],
                  isDisableHeader &&
                    !allowLogout &&
                    isConfirmedEmail &&
                    classes.disabled,
                  authStore.isServerError && classes.disabled
                )}
              >
                <NavLink
                  to={routers.programma_rosta}
                  className={clsx(classes['header__list-link'])}
                  data-step-solutions_details-4
                >
                  <StarAdminIcon />
                </NavLink>
              </li>
            )}

            {!RUSSIAN_LOCALE && (
              <li
                className={clsx(
                  classes['header__list-item'],
                  classes['header__globus'],
                  isDisableHeader && classes.disabled
                )}
              >
                <div
                  className={clsx(classes['header__list-link'])}
                  onClick={handleOpenLocalPreferencesModal}
                >
                  <GlobusGradientIcon />
                </div>

                <div className={classes.modal}>
                  <ul className={classes.modalListGlobus}>
                    {modalListItems.map((item, index) => (
                      <li key={index} className={classes.modalItem}>
                        <div className={classes.modalInnerColumn}>
                          <span className={classes.modalNameHighlight}>
                            {`${item.label}:`}
                          </span>
                          <span className={classes.modalName}>
                            {item.value}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            )}

            <li
              className={clsx(
                classes['header__list-item'],
                classes['header__user'],
                isDisableHeader &&
                  !allowLogout &&
                  isConfirmedEmail &&
                  classes.disabled,
                authStore.isServerError && classes.disabled
              )}
            >
              <NavLink
                to={path}
                className={clsx(
                  classes['header__list-link'],
                  isActiveLink && classes.active,
                  !isSuccessLocalPreferences && !allowLogout
                )}
              >
                <User name={userName} />
              </NavLink>
            </li>
          </ul>
        </div>

        {isShowHeaderAdminList && (
          <HeaderAdminList
            disabled={disabled}
            isAdmin={isAdmin}
            isManager={isManager}
            isConfirmedEmail={isConfirmedEmail}
            isTestUser={isTestUser}
            handleOpenLocalPreferencesModal={handleOpenLocalPreferencesModal}
            setIsOpenLocalPreferencesModal={setIsOpenLocalPreferencesModal}
            resetCookies={resetCookies}
            goToKokocID={goToKokocID}
          />
        )}

        {active && (
          <footer className={clsx(classes['burger-footer'])}>
            <div className={classes['burger-footer__inner']}>
              <div className={classes['burger-footer__copy']}>
                &copy; 2024 {t('KIT Global')}
              </div>
              <LinksPolitic />
            </div>
          </footer>
        )}
      </nav>
    );
  }
);

export default HeaderNav;
