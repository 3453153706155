import clsx from 'clsx';
import classes from './Footer.module.scss';
import { useTranslation } from 'react-i18next';
import { LinksPolitic } from 'components/shared/LinksPolitic';
import visa_masterCard from 'assets/img/Visa_MasterCard.svg';
import { RUSSIAN_LOCALE } from 'utils';

export const Footer = ({ className }: { className?: string }) => {
  const { t } = useTranslation();

  return (
    <footer className={clsx(classes.footer, className && classes[className])}>
      <div className="layout-inner">
        <div className={classes.inner}>
          <div className={classes.copy} translate="no">
            &copy; {new Date().getFullYear()} {t('KIT Global_footer')}
          </div>

          <LinksPolitic />

          {RUSSIAN_LOCALE && (
            <img
              className={classes.image}
              src={visa_masterCard}
              alt={'visa_masterCard'}
            />
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
